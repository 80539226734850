<template>
    <div class="vocationalSkillLevel_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.vocationalSkillLevelName"> </CourseTitle>
            <div class="content">
                <div class="vocationalSkillLevelCertificate">
                    <div class="certificateIntroduction">
                        {{ formData.vocationalSkillLevelIntroduce }}
                    </div>
                    <img :src="formData.vocationalSkillLevelImgUrl" alt="" />
                </div>
            </div>
        </div>
        <div class="module2">
            <CourseTitle :title="formData.certificateStyleName"> </CourseTitle>
            <div class="content display">
                <img :src="formData.certificateStyleImgUrl" alt="" />
            </div>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.whyRecognitionName" :isWhite="1">
            </CourseTitle>
            <div class="content gradeRrecognition">
                <ul class="gradeRrecognition_ul display">
                    <li class="gradeRrecognition_li flex-center column" v-for="(
							item, index
						) in formData.vocationalSkillLevelWhyRecognitions" :key="index">
                        <img :src="item.whyRecognitionImgUrl" alt="" />
                        <div class="title">{{ item.whyRecognitionName }}</div>
                        <div class="content_div">
                            {{ item.whyRecognitionIntroduce }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="module4" :style="{ backgroundImage: `url(${formData.learningAbilityImgUrl})` }">
            <CourseTitle :title="formData.learningAbilityName"> </CourseTitle>
            <div class="content LearningAbility"></div>
        </div>
        <div class="module5">
            <CourseTitle :title="formData.provideServerName"> </CourseTitle>
            <div class="content provideServices">
                <div class="carousel_box">
                    <el-carousel :interval="400000" type="card" height="400px">
                        <el-carousel-item v-for="item in formData.vocationalSkillLevelProvideServers" :key="item.id">
                            <div class="el_carousel_div flex-center column">
                                <img class="icon_img" :src="item.provideServerImgUrl" alt="" />
                                <div class="qualification display">
                                    {{ item.provideServerName }}
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="module6">
            <CoreCourses :coreCourseTitle="formData.electricianTrainingName" :registrationCoreCourse="formData.vocationalSkillLevelElectricianTrainings
                " :coreCourses="formData.vocationalSkillLevelElectricianTrainings[0]"></CoreCourses>
        </div>
        <div class="module7">
            <div class="content">
                <div class="oneStopService">
                    <CourseTitle :title="formData.oneStopServiceName" :contText="formData.oneStopServiceIntroduce">
                    </CourseTitle>
                    <ul>
                        <li class="oneStopService_li" v-for="(
								item, index
							) in formData.vocationalSkillLevelOneStopServices" :key="index">
                            <div class="Registration_process flex-center column"
                                v-if="index == 0 || index == 2 || index == 4">
                                <div class="process_div">
                                    {{ item.oneStopServiceIntroduce }}
                                </div>
                                <div class="num">0{{ index + 1 }}</div>
                                <div class="triangle_div"></div>
                            </div>
                            <div class="Registration_process_name display">
                                {{ item.oneStopServiceName }}
                            </div>
                            <div class="Registration_process flex-center column"
                                v-if="index == 1 || index == 3 || index == 5">
                                <div class="triangle_divs"></div>
                                <div class="num">0{{ index + 1 }}</div>
                                <div class="process_div">
                                    {{ item.oneStopServiceIntroduce }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="module8">
            <CourseTitle :title="formData.completeFacilitiesName"> </CourseTitle>
            <div class="content equipment flex">
                <div class="equipment_class">
                    <div class="list display pointer" :class="equipmentClassIndex == index ? 'active' : ''" v-for="(
							item, index
						) in formData.vocationalSkillLevelCompleteFacilitiesS" :key="index" @click="changeClass(item, index)">
                        {{ item.type == 1 ? '电工设备' : '教学现场' }}
                    </div>
                </div>
                <img :src="equipmentUrl" alt="" />
            </div>
        </div>
        <div class="module9">
            <CourseTitle :title="formData.problemSolvingName"> </CourseTitle>
            <div class="content">
                <ProblemSolving :ProblemSolvingData="formData.jobSalutationQuestions"></ProblemSolving>
            </div>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
import ProblemSolving from '@/components/problemSolving.vue'
import CoreCourses from '@/components/coreCourses'
export default {
    components: {
        BannerImg,
        CourseTitle,
        ProblemSolving,
        CoreCourses,
    },
    data() {
        return {
            equipmentClassIndex: 0,
            formData: {},
            equipmentUrl: '',
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.vocationalSkillLevelApi().then((res) => {
                this.formData = res.data
                this.equipmentUrl =
                    this.formData.vocationalSkillLevelCompleteFacilitiesS[0].electricianTrainingImgUrl
            })
        },
        changeClass(item, index) {
            this.equipmentClassIndex = index
            this.equipmentUrl = item.electricianTrainingImgUrl
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.vocationalSkillLevel_page {
    .module1 {
        height: 860px;
        background: url('../../assets/img/vocationalSkillLevel/bg1.png') no-repeat;
        background-size: 100% 100%;

        .vocationalSkillLevelCertificate {
            width: 1200px;
            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.26);
            padding: 58px 42px 77px 48px;
            background: white;
            margin-top: 30px;

            .certificateIntroduction {
                width: 1060px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 28px;
            }

            img {
                width: 1110px;
                height: 350px;
                margin-top: 30px;
            }
        }
    }

    .module2 {
        height: 674px;
        background: url('../../assets/img/vocationalSkillLevel/bg2.jpg') no-repeat;
        background-size: 100% 100%;

        img {
            width: 900px;
            height: 320px;
            margin-top: 64px;
        }
    }

    .module3 {
        height: 620px;
        background: url('../../assets/img/vocationalSkillLevel/bg3.png') no-repeat;
        background-size: 100% 100%;

        .gradeRrecognition {
            margin-top: 31px;

            .gradeRrecognition_ul {
                .gradeRrecognition_li:nth-child(1) {
                    margin-left: 28px !important;
                }

                .gradeRrecognition_li {
                    width: 278px;
                    height: 338px;
                    background: #f4f5f5;
                    border-radius: 5px;
                    margin-left: 28px;

                    img {
                        width: 98px;
                        height: 98px;
                        margin-top: 38px;
                    }

                    .title {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                        margin: 26px 0;
                    }

                    .content_div {
                        width: 244px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .module4 {
        height: 640px;
        // background: url('../../assets/img/vocationalSkillLevel/bg4.png') no-repeat;
        background-size: 100% 100%;
    }

    .module5 {
        height: 670px;

        .provideServices {
            margin-top: 30px;

            ::v-deep.el-carousel--horizontal {
                padding-top: 20px;
                padding-left: 50px;
                width: 1180px;
                margin: 0 auto;
            }

            ::v-deep.el-carousel__arrow--right {
                right: 50px;
            }

            ::v-deep.el-carousel {
                .el-carousel__item--card.is-in-stage {
                    width: 501px;
                    height: 376px;
                    box-shadow: 2px 3px 29px 0px rgba(0, 0, 0, 0.25);
                }

                .el-carousel__item--card {
                    width: 400px;
                    height: 312px;
                    box-shadow: 2px 3px 29px 0px rgba(0, 0, 0, 0.25);

                    img {
                        width: 460px;
                        height: 335px;
                        margin-top: 20px;
                    }
                }
            }

            ::v-deep.el-carousel__item--card.is-active {
                width: 501px;
                height: 376px;
                box-shadow: 2px 3px 29px 0px rgba(0, 0, 0, 0.25);
            }

            ::v-deep.el-carousel__indicators--outside {
                display: none;
            }
        }

        .el_carousel_div {
            height: 376px;
            background: white;
            position: relative;

            img {
                width: 465px;
                height: 335px;
                margin-top: 20px;
            }

            .qualification {
                width: 463px;
                height: 48px;
                opacity: 0.8;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #fff;
                background: #000;
                position: absolute;
                left: 19px;
                bottom: 20px;
            }
        }
    }

    .module7 {
        height: 650px;
        background: #f4f5f5;

        .oneStopService {
            height: 650px;
            background: url('../../assets/img/vocationalSkillLevel/bg5.png') no-repeat;
            background-size: 100% 100%;
            position: relative;

            .oneStopService_li {
                position: absolute;

                .Registration_process {
                    height: 90px;
                    background: #ffffff;
                    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.26);
                    border-radius: 5px;
                    padding-top: 18px;

                    .process_div {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                    }

                    .num {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #003c7e;
                        line-height: 24px;
                        margin: 12px 0px;
                    }

                    .triangle_div {
                        width: 0;
                        height: 0;
                        border-top: 20px solid #ffffff;
                        border-right: 20px solid transparent;
                        border-left: 20px solid transparent;
                        margin-top: 1px;
                    }

                    .triangle_divs {
                        width: 0;
                        height: 0;
                        border-left: 20px solid transparent;
                        border-right: 20px solid transparent;
                        border-bottom: 20px solid #ffffff;
                        margin-top: -38px;
                    }
                }

                .Registration_process_name {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 24px;
                    margin: 62px 0;
                }
            }

            .oneStopService_li:nth-child(1) {
                width: 180px;
                top: 207px;
                left: 98px;
            }

            .oneStopService_li:nth-child(2) {
                width: 180px;
                top: 296px;
                left: 261px;
            }

            .oneStopService_li:nth-child(3) {
                width: 207px;
                top: 207px;
                left: 410px;
            }

            .oneStopService_li:nth-child(4) {
                width: 283px;
                top: 296px;
                left: 538px;
            }

            .oneStopService_li:nth-child(5) {
                width: 250px;
                top: 207px;
                left: 720px;
            }

            .oneStopService_li:nth-child(6) {
                width: 227px;
                top: 296px;
                left: 894px;
            }

            .oneStopService_li:hover {
                .Registration_process {
                    background: #003c7e !important;
                }

                .process_div {
                    color: #ffffff !important;
                }

                .num {
                    color: #ffffff !important;
                }

                .triangle_div {
                    border-top: 20px solid #003c7e !important;
                }

                .triangle_divs {
                    border-bottom: 20px solid #003c7e !important;
                }
            }
        }
    }

    .module8 {
        height: 810px;

        .equipment {
            margin-top: 31px;
            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.26);

            .equipment_class {
                width: 210px;

                .list {
                    width: 210px;
                    height: 220px;
                    background: white;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }

                .active {
                    background: #003c7e !important;
                    color: white !important;
                }
            }

            img {
                width: 990px;
                height: 440px;
            }
        }
    }

    .module9 {
        background: #fafafa;
    }
}</style>